import React from "react";
import Seo from "@components/common/Seo";
import { Link } from "gatsby";
import { AiOutlineShopping } from "react-icons/ai";
import Logo from "@components/common/Logo";
import { useStaticQuery, graphql } from "gatsby";
import Text from "@components/common/Text";
import { MdSmsFailed } from "react-icons/md";

const Component = ({ location }) => {
    const data = useStaticQuery(graphql`
        query {
            currentBuildDate {
                currentDate
            }
        }
    `);

    if (!location?.state) {
        return null;
    }

    return (
        <>
            <Seo title={`¡Compra no realizada!`} />
            <div className="font-poppins">
                <header className="px-5 flex justify-between items-center border-b py-3">
                    <div>
                        <Link
                            to="/"
                            title="Volver al inicio"
                            className="flex items-center text-gray-600 hover:text-gray-800 duration-300"
                        >
                            <Logo className="duration-300 w-24 md:w-28" />
                        </Link>
                    </div>

                    <div>
                        <Link
                            to="/tienda"
                            title="Volver a la tienda"
                            className="flex items-center text-gray-600 hover:text-gray-800 duration-300"
                        >
                            <AiOutlineShopping className="w-8 h-8" />
                        </Link>
                    </div>
                </header>

                <main className="p-10">
                    <section className="max-w-5xl mx-auto h-rounded-r-full">
                        <div className="flex flex-col items-center mt-5 md:mt-10 pb-10">
                            <MdSmsFailed className="mx-auto text-red-600 w-40 h-40 md:w-56 md:h-56" />
                            <h1 className="text-red-600 text-3xl md:text-5xl font-light text-center mt-10">
                                ¡La compra no se ha podido realizar o se ha cancelado!
                            </h1>
                            <Text className="text-center text-lg md:text-xl mt-5">
                                Ha habido algún problema en el proceso de la realización del pedido.
                                Vúelvelo a probar en unos segundos. En caso de persistir el error
                                contactar con nosotros en la dirección info@iopos.academy.
                            </Text>
                        </div>
                    </section>
                </main>

                <footer className="bg-cindigo p-5 text-white flex items-center justify-between">
                    <div className="text-sm">
                        <p>
                            © iOpos Academia {new Date().getFullYear()}. Todos los derechos
                            reservados.
                        </p>
                        <p className="opacity-50 text-xs font-light italic">
                            Site desarrollado con GatsbyJS (build:{" "}
                            {data.currentBuildDate.currentDate}h)
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default Component;
